<template>
    <v-dialog
        content-class="border-radius-1"
        v-model="dialog"
        persistent
        min-width="500"
        max-width="500"
        :retain-focus="false"
        overlay-color="primary"
        overlay-opacity="0.75"
    >
        <v-card elevation="0" height="500">
            <v-row justify="center" class="py-12 mx-0 px-0">
                <v-img
                    :src="require('../../assets/img/sessions/notification_success.svg')"
                    max-width="200"
                />
            </v-row>
            <v-row
                justify="center"
                class="pb-6 mx-0 px-0 text-h4 text--primary font-weight-black"
            >
                {{ $t('sessions.session_activated') }}
            </v-row>
            <v-card-actions class="d-flex justify-center py-12 text--primary">
                <v-btn
                    color="#FF6F3A"
                    large
                    width="150"
                    outlined
                    class="mx-2 px-6 text-capitalize"
                    @click="$emit('close')"
                >
                    {{ $t('sessions.continue') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    props: {
        dialog: { type: Boolean, default: false },
        group: { type: Number, default: 0 },
    },
}
</script>
<style scoped></style>
