<template>
    <v-card elevation="12" class="py-6 px-8">
        <v-row>
            <v-col cols="4" class="px-0">
                <v-btn
                    class="no-uppercase px-0"
                    color="primary"
                    text
                    @click="$router.push('/teacher/sessions/list')"
                >
                    <v-img :src="require('../../assets/img/icons/back.svg')" alt="B" class="mr-2" />
                    {{ $t('sessions.back_to_sessions') }}
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" lg="4" class="mt-0 mt-lg-2 mx-0 px-0">
                <v-row class="mx-0 px-1 text-h6 font-weight-black text-color-primary">
                    {{ $t('sessions.set_new_session') }}
                </v-row>
                <SessionsNewFilters
                    class="mx-0 px-1"
                    v-on:newDateTimeExecution="setDateTimeExecution"
                    v-on:newEndingTime="setEndingTime"
                    v-on:newGroupsNumber="setGroupsNumber"
                    v-on:newSelectedClass="setSelectedClass"
                    v-on:newSessionLive="setSessionLive"
                    v-on:newStartingTime="setStartingTime"
                />
            </v-col>
            <v-col cols="12" lg="8" class="mt-0 mt-lg-2">
                <v-row class="mx-0 px-1 text-h6 font-weight-black text-color-primary">
                    {{ $t('sessions.set_up_groups') }}:
                </v-row>
                <SessionsNewGroups
                    :canActivate="canActivate"
                    :course="selectedClass"
                    :groupsNumber="groupsNumber"
                    v-on:activate="activate"
                />
                <Events section="sessions" class="mt-0 mt-lg-6" />
            </v-col>
        </v-row>
        <SessionsNewModalActivated
            :dialog="sessionActivated"
            v-on:close="afterActivation"
        />
        <v-overlay :absolute="false" :value="loading">
            <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                :width="10"
            />
        </v-overlay>
    </v-card>
</template>

<script>

import { mapState } from 'vuex'
import SessionsNewGroups from '@/components/sessions/SessionsNewGroups.vue'
import SessionsNewModalActivated from '@/components/sessions/SessionsNewModalActivated.vue'
import Events from '@/components/stats/Events.vue'
import SessionsNewFilters from '@/components/sessions/SessionsNewFilters.vue'
import WorkSessionService from '../../services/workSession'

export default {
    components: {
        SessionsNewGroups,
        SessionsNewModalActivated,
        Events,
        SessionsNewFilters,
    },
    data() {
        return {
            dateTimeExecution: '',
            endingTime: '11:00 hrs',
            groupsNumber: 3,
            loading: false,
            selectedClass: 0,
            selectedRepeat: 0,
            sessionActivated: false,
            sessionLive: false,
            startingTime: '10:00 hrs',
        }
    },
    computed: {
        ...mapState({
            teacherId: (state) => state.userData.user.id,
        }),
        items() {
            return [this.$t('sessions.sessions_data'), this.$t('sessions.live_session')]
        },
        canActivate() {
            return this.dateTimeExecution !== ''
        },
    },
    methods: {
        activate(data) {
            this.loading = true
            const dataObject = {
                dateTimeExecution: `${this.dateTimeExecution} 00:00:00`,
                workSessionType: this.sessionLive ? 'LIVE_SESSION' : 'WORK_GROUP',
                status: true,
                startingTime: this.startingTime.substring(0, 2),
                endingTime: this.endingTime.substring(0, 2),
                workGroupList: data,
                duration: '',
            }
            WorkSessionService.create(this.teacherId, this.selectedClass, dataObject)
                .then(() => {
                    this.loading = false
                    this.sessionActivated = true
                })
                .catch((error) => console.error(error))
        },
        afterActivation() {
            this.sessionActivated = false
            this.$router.push('/teacher/sessions/list')
        },
        setDateTimeExecution(dateTimeExecution) {
            this.dateTimeExecution = dateTimeExecution
        },
        setEndingTime(endingTime) {
            this.endingTime = endingTime
        },
        setGroupsNumber(groupsNumber) {
            this.groupsNumber = groupsNumber
        },
        setSelectedClass(selectedClass) {
            this.selectedClass = selectedClass
        },
        setSessionLive(sessionLive) {
            this.sessionLive = sessionLive
        },
        setStartingTime(startingTime) {
            this.startingTime = startingTime
        },
    },
}
</script>
<style scoped>
.no-uppercase {
    text-transform: none;
}
.text-color-primary {
    color: #514773;
}
</style>
