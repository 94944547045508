<template>
    <v-row>
        <v-col cols="7" sm="9">
            <v-row align="center">
                <v-checkbox
                    class="mt-5"
                    v-model="sessionLive"
                />
                {{ $t('sessions.session_live') }}
            </v-row>
            <v-row>
                {{ $t('sessions.number_groups') }}:
            </v-row>
            <v-row>
                <v-select
                    item-text="label"
                    item-value="value"
                    :items="sessionsNumbers"
                    outlined
                    v-model="groupsNumber"
                    append-icon="mdi-menu-down text-primary mdi-48px"
                    :disabled="sessionLive"
                >
                    <template v-slot:selection="{ item }">
                        <v-img
                            :src="require('../../assets/img/icons/groups.svg')"
                            alt="B"
                            class="mr-2"
                            max-height="30"
                            max-width="30"
                        />
                        {{ item.label }}
                    </template>
                </v-select>
            </v-row>
            <v-row>
                {{ $t('sessions.select_class') }}:
            </v-row>
            <v-row>
                <v-select
                    item-text="name"
                    item-value="id"
                    :items="courses"
                    outlined
                    v-model="selectedClass"
                    append-icon="mdi-menu-down text-primary mdi-48px"
                >
                    <template v-slot:selection="{ item }">
                        <v-icon size="30" class="mr-2 color-icon">
                            mdi-clipboard-check-outline
                        </v-icon>
                        {{ item.name }}
                    </template>
                </v-select>
            </v-row>
            <v-row>
                {{ $t('sessions.number_subgroups') }}:
            </v-row>
            <v-row>
                <v-select
                    item-text="label"
                    item-value="value"
                    :items="sessionsSubNumbers"
                    outlined
                    v-model="subgroupsNumber"
                    append-icon="mdi-menu-down text-primary mdi-48px"
                    :disabled="sessionLive"
                >
                    <template v-slot:selection="{ item }">
                        <v-icon size="30" class="mr-2 color-icon">
                            mdi-clipboard-check-outline
                        </v-icon>
                        {{ item.label }}
                    </template>
                </v-select>
            </v-row>
            <v-row>
                {{ $t('sessions.select_start_date') }}:
            </v-row>
            <v-row>
                <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="dateFormated"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                        >
                            <template v-slot:prepend-inner>
                                <v-img
                                    :src="require('../../assets/img/icons/library-enddate-icon.svg')"
                                    alt="B"
                                    class="mr-2"
                                    min-height="30"
                                    max-height="30"
                                    min-width="30"
                                    max-width="30"
                                />
                            </template>
                        </v-text-field>
                    </template>
                    <v-date-picker
                        :min='getMinDate'
                        v-model="dateTimeExecution"
                        @input="dateMenu = false"
                    />
                </v-menu>
            </v-row>
            <v-row>
                <v-col cols="6" class="pl-0">
                    {{ $t('sessions.starting_time') }}:
                </v-col>
                <v-col cols="6" class="px-0">
                    {{ $t('sessions.ending_time') }}:
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" class="pl-0">
                    <v-select
                        :items="getHours()"
                        outlined
                        v-model="startingTime"
                        append-icon="mdi-menu-down text-primary mdi-48px"
                    >
                        <template v-slot:selection="{ item }">
                            <v-img
                                :src="require('../../assets/img/icons/reading-hours.svg')"
                                alt="B"
                                class="mr-2"
                                max-height="30"
                                max-width="30"
                            />
                            {{ item }}
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="6" class="px-0">
                    <v-select
                        :items="getHours()"
                        outlined
                        v-model="endingTime"
                        append-icon="mdi-menu-down text-primary mdi-48px"
                    >
                        <template v-slot:selection="{ item }">
                            <v-img
                                :src="require('../../assets/img/icons/reading-hours.svg')"
                                alt="B"
                                class="mr-2"
                                max-height="30"
                                max-width="30"
                            />
                            {{ item }}
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row>
                {{ $t('sessions.repeat') }}:
            </v-row>
            <v-row>
                <v-select
                    item-text="label"
                    item-value="value"
                    :items="repeatOptions"
                    outlined
                    v-model="selectedRepeat"
                    append-icon="mdi-menu-down text-primary mdi-48px"
                >
                    <template v-slot:selection="{ item }">
                        <v-img
                            :src="require('../../assets/img/icons/repeat.svg')"
                            alt="B"
                            class="mr-2"
                            max-height="30"
                            max-width="30"
                        />
                        {{ item.label }}
                    </template>
                </v-select>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            dateFormated: '',
            dateMenu: false,
            dateTimeExecution: '',
            endingTime: '11:00 hrs',
            groupsNumber: 3,
            subgroupsNumber: 0,
            selectedClass: 1,
            selectedRepeat: 0,
            sessionLive: false,
            startingTime: '10:00 hrs',
        }
    },
    computed: {
        ...mapState({
            courses: (state) => state.userData.courses,
        }),
        getMinDate() {
            const today = new Date()
            const month = (today.getMonth() + 1) < 10 ? `0${(today.getMonth() + 1)}` : (today.getMonth() + 1)
            const day = today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()
            const result = `${today.getFullYear()}-${month}-${day}`
            return result
        },
        repeatOptions() {
            return [
                { label: this.$t('sessions.repeat_options[0]'), value: 0 },
                { label: this.$t('sessions.repeat_options[1]'), value: 1 },
            ]
        },
        sessionsNumbers() {
            return [
                { label: this.$t('sessions.sessions_number[0]'), value: 0 },
                { label: this.$t('sessions.sessions_number[1]'), value: 1 },
                { label: this.$t('sessions.sessions_number[2]'), value: 2 },
                { label: this.$t('sessions.sessions_number[3]'), value: 3 },
                { label: this.$t('sessions.sessions_number[4]'), value: 4 },
                { label: this.$t('sessions.sessions_number[5]'), value: 5 },
                { label: this.$t('sessions.sessions_number[6]'), value: 6 },
                { label: this.$t('sessions.sessions_number[7]'), value: 7 },
            ]
        },
        sessionsSubNumbers() {
            return [
                { label: this.$t('sessions.sessions_subnumber[0]'), value: 0 },
            ]
        },
    },
    mounted() {
        this.selectedClass = this.courses[0].id
    },
    methods: {
        getHours() {
            const response = []
            for (let index = 0; index < 24; index += 1) {
                let value = ''
                if (index < 10) {
                    value = `0${index}:00 hrs`
                } else {
                    value = `${index}:00 hrs`
                }
                response.push(value)
            }
            return response
        },
        getMinHour() {
            const h = (new Date()).getHours()
            const min1 = h < 10 ? `0${h}:00 hrs` : `${h}:00 hrs`
            const min2 = (h + 1) < 10 ? `0${(h + 1)}:00 hrs` : `${(h + 1)}:00 hrs`
            return [min1, min2]
        },
    },
    watch: {
        dateTimeExecution() {
            const vals = this.dateTimeExecution.split('-')
            this.dateFormated = this.$options.filters.dateStartDate(
                [Number(vals[0]), Number(vals[1]), Number(vals[2])],
                { date: true, location: this.$i18n.locale },
            )
            this.$emit('newDateTimeExecution', this.dateTimeExecution)
        },
        endingTime() {
            this.$emit('newEndingTime', this.endingTime)
        },
        groupsNumber() {
            this.$emit('newGroupsNumber', this.groupsNumber)
        },
        selectedClass() {
            this.$emit('newSelectedClass', this.selectedClass)
        },
        sessionLive() {
            if (this.sessionLive) {
                this.groupsNumber = 0
            }
            this.$emit('newSessionLive', this.sessionLive)
        },
        startingTime() {
            this.$emit('newStartingTime', this.startingTime)
        },
    },
}
</script>

<style scoped>
.color-icon {
    color:#B4A9CE;
}
</style>
