<template>
    <v-dialog
        content-class="border-radius-1"
        v-model="dialog"
        persistent
        min-width="700"
        max-width="950"
        :retain-focus="false"
        overlay-color="primary"
        overlay-opacity="0.75"
        scrollable
    >
        <v-card elevation="0" class="overflow-y-auto">
            <v-row justify="center" class="mx-0 px-0">
                <p class="text-h5 text--primary font-weight-black pt-6 my-0">
                    {{ $t('sessions.group') }} {{ group }}
                </p>
                <v-col cols="11" class="py-0 border-bottom">
                    <v-row justify="center">
                        <v-col cols="6">
                            <v-stepper alt-labels v-model="steeper" class="v-steeper-box">
                                <v-stepper-header>
                                    <v-stepper-step :complete="steeper > 1" step="1">
                                        {{ $t('sessions.set_up') }}
                                    </v-stepper-step>
                                    <v-divider />
                                    <v-stepper-step step="2" class="px-0">
                                        {{ $t('sessions.instructional_goal') }}
                                    </v-stepper-step>
                                    <v-divider />
                                    <v-stepper-step step="3">
                                        {{ $t('sessions.confirm') }}
                                    </v-stepper-step>
                                </v-stepper-header>
                            </v-stepper>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="11" v-show="steeper===1">
                    <v-row
                        class="text--primary font-weight-black text-h6 mx-0"
                        justify="space-between"
                    >
                        <v-col class="pb-0">
                            <p>
                                {{ $t('sessions.groups_by_tier') }}:
                            </p>
                        </v-col>
                        <v-col class="pb-0 customCols">
                            <p>
                                {{ $t('sessions.group') }} {{ group }}:
                            </p>
                        </v-col>
                    </v-row>
                    <v-card elevation="0" class="overflow-y-auto" height="450">
                        <draggable
                            v-model="students"
                            tag="v-row"
                            class="justify-space-around mx-0"
                        >
                            <v-col
                                v-for="list in students"
                                :key="list.index"
                                class="customCols dashed-border rounded-lg"
                            >
                                <p :class="getTierClass(list.index)" v-show="list.index!==3">
                                    {{ getTitle(list.index) }}
                                </p>
                                <draggable
                                    :list="list.students"
                                    tag="v-row"
                                    :group="list.group"
                                    :sort="false"
                                    class="align-content-start"
                                    style="min-height: 300px;"
                                >
                                    <v-col
                                        v-for="item in list.students"
                                        :key="item.id"
                                        cols="12"
                                        class="px-3 py-0 pointer"
                                    >
                                        <v-row align="center">
                                            <v-col cols="3" class="pa-0">
                                                <v-img
                                                    :src="getAvatarSrc(item.avatar)"
                                                    max-height="40"
                                                    max-width="40"
                                                />
                                            </v-col>
                                            <v-col
                                                cols="9"
                                                class="py-0 pr-0 text-body-2 text-capitalize font-weight-black text-gray"
                                            >
                                                {{ item.name | capitalize }}
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </draggable>
                            </v-col>
                        </draggable>
                    </v-card>
                </v-col>
                <v-col cols="11" v-show="steeper===2">
                    <v-row>
                        <v-col cols="7">
                            <v-row justify="space-between">
                                <v-col cols="5" class="text-h6 text--primary font-weight-black">
                                    {{ $t('sessions.instructional_goal') }}:
                                </v-col>
                                <v-col cols="7">
                                    <v-row class="mt-1 mx-0">
                                        {{ $t('sessions.focus_skill') }}:
                                    </v-row>
                                    <v-radio-group mandatory v-model="focusSkill">
                                        <v-chip
                                            v-for="skill in skills"
                                            :key="skill.value"
                                            :class="`my-1 py-5 px-2 ${focusSkill===skill.value ? 'radio-active' : 'radio'}`"
                                            outlined
                                            label
                                        >
                                            <v-radio
                                                :label="skill.name"
                                                :value="skill.value"
                                            />
                                        </v-chip>
                                    </v-radio-group>
                                </v-col>
                            </v-row>

                        </v-col>
                        <v-col cols="5">
                            <v-row justify="center">
                                <v-col cols="8">
                                    <v-row class="mb-6 mx-0">
                                        {{ $t('sessions.number_short_texts') }}:
                                    </v-row>
                                    <v-btn-toggle
                                        v-model="numberOfText"
                                        group
                                        color="primary"
                                        mandatory
                                    >
                                        <v-btn
                                            active-class="border-color-active"
                                            class="border-color rounded"
                                            :value="i"
                                            outlined
                                            v-for="i in [2, 3, 4, 5]"
                                            :key="i"
                                        >
                                            {{ i }}
                                        </v-btn>
                                    </v-btn-toggle>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-card-actions class="d-flex justify-center py-6">
                    <v-btn
                        color="#FF6F3A"
                        large
                        width="150"
                        outlined
                        class="mx-2 px-6 text-capitalize"
                        @click="closeModal()"
                    >
                        {{ $t('sessions.cancel') }}
                    </v-btn>
                    <v-btn
                        color="#FF6F3A"
                        large
                        width="150"
                        class="mx-2 px-6 text-capitalize text-white"
                        @click="nextModal()"
                        :disabled="steeper === 1 && students[3].length===0"
                    >
                        {{ steeper === 1 ? $t('sessions.next_step') : $t('sessions.confirm') }}
                    </v-btn>
                </v-card-actions>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
import draggable from 'vuedraggable'
import CourseService from '../../services/course'

export default {
    props: {
        dialog: { type: Boolean, default: false },
        group: { type: Number, default: 0 },
        course: { type: Number, default: 0 },
        studentsInList: { type: Array, default: null },
    },
    components: {
        draggable,
    },
    data() {
        return {
            data: [],
            focusSkill: 0,
            numberOfText: 2,
            steeper: 1,
            students: [
                {
                    index: 0,
                    group: {
                        name: 'Students',
                        pull: ['Result'],
                        put: false,
                    },
                    students: [],
                },
                {
                    index: 1,
                    group: {
                        name: 'Students',
                        pull: ['Result'],
                        put: false,
                    },
                    students: [],
                },
                {
                    index: 2,
                    group: {
                        name: 'Students',
                        pull: ['Result'],
                        put: false,
                    },
                    students: [],
                },
                {
                    index: 3,
                    group: {
                        name: 'Result',
                        pull: false,
                        put: true,
                    },
                    students: [],
                },
            ],
        }
    },
    mounted() {
        this.loadData()
    },
    computed: {
        skills() {
            return [
                { name: this.$t('sessions.skills_options[0]'), value: 0 },
                { name: this.$t('sessions.skills_options[1]'), value: 1 },
                { name: this.$t('sessions.skills_options[2]'), value: 2 },
                { name: this.$t('sessions.skills_options[3]'), value: 3 },
            ]
        },
    },
    methods: {
        closeModal() {
            this.focusSkill = 0
            this.numberOfText = 2
            this.steeper = 1
            this.mapData()
            this.$emit('close')
        },
        hasStudents() {
            if (this.students[0].students.length === 0
                && this.students[1].students.length === 0
                && this.students[2].students.length === 0) {
                this.$emit('students', false)
            } else {
                this.$emit('students', true)
            }
        },
        confirm() {
            const studentList = []
            this.students[3].students.forEach((element) => studentList.push(element.id))
            const data = {
                focusSkill: this.focusSkill,
                qtyOfTests: this.numberOfText,
                students: studentList,
            }
            this.focusSkill = 0
            this.numberOfText = 2
            this.steeper = 1
            this.$emit('confirm', data)
        },
        getAvatarSrc(avatar) {
            return `${process.env.VUE_APP_S3RESOURCES}/avatar/${avatar.name}/${avatar.value}`
        },
        getGroup(i) {
            return {
                name: i < 3 ? 'Students' : 'Result',
                pull: i < 3 ? ['Result'] : ['Students'],
                put: i === 3,
            }
        },
        getTierClass(i) {
            if (i === 3) return ''
            const classes = ['tier-1', 'tier-2', 'tier-3']
            return `py-1 pl-1 mr-6 rounded ${classes[i]}`
        },
        getTitle(i) {
            if (i === 3) return ''
            return this.$t(`sessions.tier_text[${i}]`)
        },
        filterIncluded(list) {
            let result = JSON.parse(JSON.stringify(list))
            if (this.studentsInList && this.studentsInList.length > 0) {
                result = list.filter((e) => !this.studentsInList.includes(e.id))
            }
            return result
        },
        mapData() {
            if (this.data.length === 0) return
            this.students[0].students = this.filterIncluded(this.madStudent(1))
            this.students[1].students = this.filterIncluded(this.madStudent(2))
            this.students[2].students = this.filterIncluded(this.madStudent(3))
            this.students[3].students = []
            this.hasStudents()
        },
        madStudent(tier) {
            return this.data.find((element) => element.tier === tier)
                .userIdList.map((item) => (
                    { id: item.id, name: `${item.firstname} ${item.lastname}`, avatar: item.avatar }
                ))
        },
        loadData() {
            if (this.course === 0) return
            CourseService.getUserTierListByCourseId(this.course)
                .then((response) => {
                    this.data = response.content
                    this.mapData()
                })
                .catch((error) => console.error(error))
        },
        nextModal() {
            if (this.steeper === 1) {
                this.steeper += 1
            } else {
                this.confirm()
            }
        },
    },
    watch: {
        course() {
            if (this.course !== 0) {
                this.loadData()
            }
        },
        studentsInList() {
            this.mapData()
        },
    },
}
</script>
<style scoped>
.v-steeper-box {
    box-shadow: none;
}
.border-bottom {
    border-bottom-color: #C9C4DB;
    border-bottom-style: dashed;
    border-bottom-width: 1px;
}
.text-white {
    color: white;
}
.dashed-border {
    border-color: #B9B1D5;
    border-style: solid;
    border-width: 2px;
}
.customCols {
    width: 24%;
    max-width: 24%;
    flex-basis: 24%;
}
.text-gray {
    color: #8D8D8D;
}
.pointer {
    cursor: pointer;
}
.tier-1 {
    background-color: #34DBAA;
    color: white;
}
.tier-2 {
    background-color: #FDDC61;
    color: black;
}
.tier-3 {
    background-color: #8320FD;
    color: white;
}
.border-color {
    border-color: #E0DAEF !important;
    border-width: 2px !important;
}
.border-color-active {
    border-color: #B9B1D5 !important;
}
.radio {
    border-color: #E0DAEF !important;
    border-width: 2px !important;
}
.radio-active {
    border-color: #B9B1D5 !important;
    border-width: 2px !important;
}
</style>
