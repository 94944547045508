<template>
    <v-card elevation="2" :min-height="420" class="mt-4 mb-12 px-4 mr-6">
        <v-row>
            <v-col cols="6" v-for="item in (groupsNumber+1)" :key="item">
                <v-card
                    :min-height="200"
                    hover
                    class="dashed-border"
                    @click="showModal(item)"
                    :disabled="listOfGroups[item-1] && listOfGroups[item-1].name !== ''"
                >
                    <v-row justify="center" class="row-centered">
                        <v-btn
                            class="btn-color text-capitalize btn-centered"
                            outlined
                            width="160"
                            v-if="!(listOfGroups[item-1] && listOfGroups[item-1].studentIdList)"
                        >
                            {{ `${$t('sessions.set_group')} ${item}` }}
                        </v-btn>
                        <v-card class="card-centered" outlined v-else width="200">
                            <v-row class="mx-0 text--primary">
                                <v-col class="my-1 band rounded-r-lg" cols="1" />
                                <v-col cols="9" class="mx-3">
                                    <v-row class="pb-3 text-subtitle-1 font-weight-black">
                                        {{ listOfGroups[item-1].name }}
                                    </v-row>
                                    <v-row class="py-0 text-caption">
                                        {{ getStudentsText(listOfGroups[item-1].studentIdList.length) }}
                                    </v-row>
                                    <v-row class="py-0 text-caption">
                                        {{ getSkill(listOfGroups[item-1].skill) }}
                                    </v-row>
                                    <v-row class="py-0 text-caption">
                                        {{ listOfGroups[item-1].qtyOfTests }}
                                        {{ $t('sessions.short_texts') }}
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-card-actions
            class="d-flex justify-end py-6"
            v-if="numberOfConfirmations>groupsNumber"
        >
            <v-btn
                color="#FF6F3A"
                large
                width="160"
                outlined
                class="px-6 text-capitalize"
                :disabled="!canActivate"
                @click="activateSession()"
            >
                {{ $t('sessions.activate_session') }}
            </v-btn>
        </v-card-actions>
        <SessionsNewModal
            :course="course"
            :dialog="groupSelector"
            :group="selectedGroup"
            :studentsInList="studentsInList"
            v-on:close="closingModal"
            v-on:confirm="confirmedModal"
            v-on:students="studentsFromModal"
        />
        <SessionsNewModalConfirmed
            :dialog="groupConfirmed"
            :group="selectedGroup"
            v-on:close="groupConfirmed = false"
        />
        <v-overlay :absolute="true" :value="loading" class="text-h4">
            {{ $t('sessions.no_students_data') }}
        </v-overlay>
    </v-card>
</template>

<script>
import SessionsNewModal from './SessionsNewModal.vue'
import SessionsNewModalConfirmed from './SessionsNewModalConfirmed.vue'

export default {
    props: {
        canActivate: { type: Boolean, default: false },
        course: { type: Number, default: 0 },
        groupsNumber: { type: Number, default: 0 },
    },
    components: {
        SessionsNewModal,
        SessionsNewModalConfirmed,
    },
    data() {
        return {
            groupConfirmed: false,
            groupSelector: false,
            listOfGroups: [],
            loading: false,
            numberOfConfirmations: 0,
            selectedGroup: 0,
            studentsInList: [],
        }
    },
    methods: {
        activateSession() {
            this.$emit('activate', this.listOfGroups)
        },
        closingModal() {
            this.groupSelector = false
        },
        confirmedModal(data) {
            const skills = [null, 'LOC', 'IN', 'RV']
            this.numberOfConfirmations += 1
            this.groupSelector = false
            this.groupConfirmed = true
            this.listOfGroups[this.selectedGroup - 1] = {
                name: `${this.$t('sessions.group')} ${this.selectedGroup}`,
                description: '',
                qtyOfTests: data.qtyOfTests,
                studentIdList: data.students,
                skill: skills[data.focusSkill],
            }
            this.studentsInList.push(...data.students)
        },
        getStudentsText(number) {
            const text = this.$t(`sessions.student_students[${number > 1 ? 1 : 0}]`)
            return `${number} ${text}`
        },
        getSkill(skill) {
            let param = 0
            if (skill === 'LOC') {
                param = 1
            }
            if (skill === 'IN') {
                param = 2
            }
            if (skill === 'RV') {
                param = 3
            }
            return this.$t(`sessions.skills_options[${param}]`)
        },
        studentsFromModal(hasStudents) {
            this.loading = !hasStudents
        },
        loadData() {
            this.numberOfConfirmations = 0
            this.listOfGroups = []
            this.studentsInList = []
        },
        showModal(item) {
            this.groupSelector = true
            this.selectedGroup = item
        },
    },
    watch: {
        groupsNumber() {
            this.loadData()
        },
    },
}
</script>
<style scoped>
.v-sheet.v-card--hover:hover {
    transition: border-style border-width margin 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    border-style: solid;
    border-width: 5px;
    margin: 0px;
}
.btn-color {
    border-color: #B9B1D5;
    color: #B9B1D5;
}
.dashed-border {
    border-color: #B9B1D5;
    border-style: dashed;
    border-width: 2px;
    margin: 3px;
}
.row-centered {
    height: 200px;
    margin: auto;
}
.btn-centered {
    margin: auto;
}
.card-centered {
    margin: auto;
}
.band {
    background-color: #34DBAA;
}
</style>
